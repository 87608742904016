<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 32">
            <path d="m26.4438 10.6501-7.1093 5.8675c-1.3432 1.0816-3.233 1.0816-4.5762 0l-7.16923-5.8675"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path d="M24.8542 30.3787C29.7203 30.3924 33 26.3344 33 21.3469V10.193c0-4.98754-3.2797-9.04554-8.1458-9.04554H9.14582C4.27966 1.14746 1 5.20546 1 10.193v11.1539c0 4.9875 3.27966 9.0455 8.14582 9.0318H24.8542Z"
                clip-rule="evenodd"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </svg>
)
</script>

<style lang="scss" scoped>

</style>
